/*******************************************
Media (Local and Remote video styles)
*******************************************/

.media-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  .video-hidden {
    background-color: $color-video-bg;
    border: 1px solid rgba($color-grey06, .50);
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: $semi-bold;
    color: $color-grey03;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}

@mixin video-thumb() {
  background-color: $color-grey07;
  border-radius: 6px;
  width: 84px;
  height: 84px;
  overflow: hidden;

  > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include break-min($break-lrg + 1) {
    width: auto;
    max-width: 160px;
    height: calc(160px * .7);
  }
}

.local-media {
  @include video-thumb();
  position: absolute;
  right: 5px;
  bottom: 63px;
  z-index: 2;

  @include break-min(376px) {
    bottom: 74px;
  }

  @include break-min($break-med) {
    bottom: 5px;
  }

  @include break-min($break-lrg + 1) {
    left: 32px;
    bottom: 32px;
  }

  .video-hidden {
    background: black;
    display: none;

    @include break-min($break-lrg + 1) {
      display: flex;
    }
  }

  &.mirror {
    &.user > video {
      transform: scaleX(-1);
    }
  }

  .status-bar {
    align-items: center;
    position: absolute;
    bottom: 4px;
    left: 4px;
    z-index: 3;

    > i {
      float: left;
      margin: 0 3px 0 0;
      font-size: 18px;
      padding: 4px;
    }

    .icon-video {
      color: $color-active;
      display: none;
    }

    .icon-mic {
      color: $color-active;
      display: none;
    }

    .icon-mic-off,
    .icon-video-off {
      color: white;
      border-radius: 50%;
      background: $color-danger
    }
  }

  .presenting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px;

    > span {
      font-size: 12px;
      color: white;
    }

    button {
      margin: 10px 0 0;
    }
  }
}

.presentation-media {
  display: block;
  background-color: $color-video-bg;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include break-min($break-lrg + 1) {
    bottom: 176px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.remote-media {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-wrap: wrap;
  background-color: $color-video-bg;
  border: 1px solid $color-video-bg;
  z-index: 1;

  .participant-name {
    color: white;
    font-size: 13px;
    position: absolute;
    bottom: 8px;
    right: 14px;
    text-align: right;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.34);
    z-index: 3;

    @include break-max($break-lrg) {
      bottom: auto;
      top: 8px;
    }
  }

  .status-bar {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 31px;
    right: 4px;
    font-size: 20px;
    z-index: 3;

    @include break-max($break-lrg) {
      bottom: auto;
      top: 31px;
    }

    > i {
      margin: 0 0 0 3px;
      padding: 4px;
    }

    .icon-video {
      display: none
    }

    .icon-mic {
      color: $color-active;
    }

    .icon-mic-off,
    .icon-video-off {
      color: white;
      border-radius: 50%;
      background: $color-danger;
    }

    .icon-mic:hover,
    .icon-mic-off:hover {
      cursor: pointer;

      + .status-mic > label {
        display: block;
      }

      + .status-mic > .muted {
        width: 140px;
      }
    }

    .status-mic {
      display: block;
      position: relative;

      label {
        background-color: $color-grey06;
        border-radius: $radius-sm;
        color: white;
        display: none;
        font-size: 12px;
        font-weight: $semi-bold;
        line-height: normal;
        position: absolute;
        right: 28px;
        top: 0;
        text-align: center;
        padding: 2px 4px;
      }
    }
  }

  .footer-bar {
    background-color: $color-active;
    color: white;
    display: none;
    font-size: 11px;
    font-weight: $semi-bold;
    text-align: center;
    padding: 3px 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    &.show {
      display: block;
      z-index: 2;
    }
  }

  > div {
    flex: 1;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;

    > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-video-bg;
      object-fit: contain;
    }
  }

  &.presentation {
    top: auto;
    left: 200px;
    right: 0;
    bottom: 32px;
    padding: 0;

    @include break-max($break-lrg) {
      display: none;
    }

    > div {
      @include video-thumb();
      margin: 0 5px;

      .participant-name {
        display: none;
      }
    }

    .status-bar {
      top: 4px;
      bottom: auto;
      font-size: 18px;
    }

    // If there is only one other participant during a screenshare, do not show thumbnails.
    &.count-1 {
      display: none;

      + .presentation-media {
        bottom: 0;
      }
    }
  }

  &:not(.presentation) {
    &.count-2 > div > video {
      object-fit: contain;
    }

    @include break-max($break-sm) {
      justify-content: flex-start;

      // One column when there are up to three participants
      &.count-2,
      &.count-3 {
        flex-direction: column;
      }

      // Two column when there are 4 to 12 participants
      &.count-4 > div,
      &.count-5 > div,
      &.count-6 > div,
      &.count-7 > div,
      &.count-8 > div,
      &.count-9 > div,
      &.count-10 > div,
      &.count-11 > div,
      &.count-12 > div {
        max-width: calc(100% / 2);
        flex-basis: calc(100% / 2);
      }
    }

    @include break-min($break-sm + 1) {
      // Two column layout when there are up to 4 participants
      &.count-2 > div,
      &.count-3 > div,
      &.count-4 > div {
        max-width: calc(100% / 2);
        flex-basis: calc(100% / 2);
      }

      // Three column layout when there are 5 to 12 participants
      &.count-5 > div,
      &.count-6 > div,
      &.count-7 > div,
      &.count-8 > div,
      &.count-9 > div,
      &.count-10 > div,
      &.count-11 > div,
      &.count-12 > div {
        max-width: calc(100% / 3);
        flex-basis: calc(100% / 3);
      }
    }
  }
}
