/*******************************************
Invite module
*******************************************/

.invite {
  display: flex;
  flex-direction: column;

  hr {
    margin: 0;
  }

  .invite-form {
    position: relative;
    margin-bottom: 42px;

    .input-group {
      margin: 0;
    }

    .contacts-list {
      background-color: white;
      box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.10);
      padding: 8px 0;
      position: absolute;
      top: 41px;
      left: 0;
      right: 0;

      .contact-item {
        cursor: pointer;
        padding: 0 16px;
        font-size: 14px;
        line-height: 36px;

        &:hover {
          background: $color-grey01;
        }
      }
    }
  }

  .invite-list {
    margin: 0 0 24px;

    > p {
      font-size: 12px;
      font-weight: $semi-bold;
      margin: 0 0 14px;
    }

    .meta {
      margin-bottom: 12px;
    }

    .invite-contact {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      margin-bottom: 4px;
      font-size: 12px;
      background-color: #e9eaeb;
      border-radius: 2px;
      padding: 8px 16px;
      margin-bottom: 4px;
      
      &.resend-invite {
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
        border-top: 1px solid;

        .invite-container {
          padding: 5px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;  
          width: 100%;
        }

        i {
          padding: 0 16px;
        }

        .icon-deny {
          color: $color-danger;
        }

        &:last-child {
          border-bottom: 1px solid;
        }
      }

      i {
        font-size: 16px;
      }

      .invite-value {
        flex: 1;
      }

      .joined-participants-actions {
        display: inline-block;

        .dropdown-button {
          border: none;
          cursor: pointer;

          i {
            padding: 0 10px;
          }
        }

        .dropdown-content {
          position: absolute;
          background-color: white;
          border-radius: 3px;
          min-width: auto;
          right: 12%;
          overflow: auto;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }
        
        .dropdown-content span {
          color: $color-grey06;
          padding: 12px 25px;
          display: block;
          cursor: pointer;
        }
        
        .dropdown-content span:hover {
          background-color: #f1f1f1;
        }

        i {
          vertical-align: text-top;
          padding: 0 10px 0 0;
        }
      }
    }
  }

  .invite-empty {
    text-align: center;
    margin: 40px auto 0;

    > img {
      display: block;
      height: 110px;
      width: auto;
      margin: 0 auto 12px;
    }

    > p {
      color: $color-teal;
      margin: 0;
    }

    + .invite-list.sent {
      margin-top: 40px;
    }
  }

  .send-btn-input {
    width: 100%;
    max-width: 130px;
    font-size: 14px;
    bottom: 6px;
    height: 36px;
  }

  .resend-btn {
    width: 100%;
    max-width: 74px;
    font-size: 12px;
    bottom: 6px;
    height: 28px;
  }

  .send-btn {
    width: 100%;
    max-width: 180px;
    margin: 24px auto 0;

    + .invite-list.sent {
      margin-top: 38px;
    }
  }
}

