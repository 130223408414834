/*******************************************
Layout Styles
*******************************************/

.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  .evisit-logo {
    display: block;
    width: 72px;
    height: auto;
    position: absolute;
    top: 24px;
    left: 32px;
    z-index: 99;

    @include break-max($break-lrg) {
      top: 10px;
      left: 12px;
    }
  }

  .splash-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
  }

  .get-data {
    position: absolute;
    top: 24px;
    right: 32px;
    z-index: 100;
  }

  .connect-quality {
    position: absolute;
    left: 32px;
    top: 80px;
    z-index: 99;
    height: auto;
    font-size: 12px;
    color: $color-teal;
  }

  .room-empty {
    color: white;
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 15px;

    input {
      color: white;
    }

    > h1 {
      color: $color-grey02;

      @include break-max($break-sm) {
        font-size: 24px;
      }
    }

    button {
      margin: auto;
    }

    &.unsupported {
      text-align: left;

      ul {
        display: block;
      }
    }
  }
}
