/*******************************************
Join Room
*******************************************/

.join-wrapper {
  background-color: white;
  border-radius: $radius-md;
  width: 100%;
  padding: 40px 64px 45px;
  max-width: 440px;
  position: relative;
  z-index: 1;

  @include break-max($break-sm) {
    padding: 24px 32px;
    width: calc(100% - 20px);

    h1 {
      font-size: 24px;
    }
  }

  .input-group + .error {
    margin-top: -12px;
  }

  input,
  button {
    width: 100%;
  }

  .waiting {
    text-align: center;

    h1 {
      margin-bottom: 8px;
    }

    p {
      margin: 0;
    }
  }
}
