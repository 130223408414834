/*******************************************
Ended Visit Modal
*******************************************/

.ended-modal {
  background-color: #FFFFFF;
  border-radius: $radius-md;
  width: 100%;
  max-width: 440px;
  padding: 48px 70px 58px;
  position: relative;
  box-shadow: 0 2px 40px #0000001A;
  opacity: 1;
  z-index: 1;

  .ended-content {
    color: #303A40;
    opacity: 1;
    letter-spacing: 0;
    width: 300px;

    h1 {
      font: 28px/24px Open Sans;
      margin-bottom: 8px;
    }

    p {
      font: 16px/24px Open Sans;
      margin: 0;
    }

    .participant-duration-title {
      font: 600 16px/24px Open Sans;
      padding-top: 40px;
      padding-bottom: 18px;
    }
  }
}

.ended-footer {
  background-color: #F5F5F5;
  border-radius: 0 0 $radius-md $radius-md;
  padding: 24px 70px;
  width: 100%;
  max-width: 440px;
  position: relative;
  opacity: 1;
  z-index: 1;
  top: -10px;
  text-align: center;
  letter-spacing: 0;
  color: #303A40;

  p {
    font: 16px/24px Open Sans;
    width: 300px;
    height: 22px;
    margin: 0;
  }
}
