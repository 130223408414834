/*******************************************
Form Fields and Buttons
*******************************************/

// Buttons
.button,
button,
input[type="button"],
input[type="submit"] {
  background-color: $color-teal;
  border: none;
  border-radius: $radius-sm;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  padding: 8px 24px;
  text-align: center;
  transition: $hover-duration background-color;

  &:hover {
    background-color: darken($color-teal, 8%);
  }

  &.dark {
    background-color: $color-button-dark;

    &:hover {
      background-color: lighten($color-button-dark, 12%);
    }
  }

  &.neutral {
    background-color: $color-grey05;

    &:hover {
      background-color: darken($color-grey05, 12%);
    }
  }

  &.warn {
    background-color: $color-warn;

    &:hover {
      background-color: darken($color-warn, 12%);
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: .50;
  }

  &.transparent {
    background-color: transparent;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $color-teal;
    color: $color-teal;

    &:hover {
      background-color: darken(white, 5%);
    }
  }

  &.outline-white {
    background-color: transparent;
    border: 1px solid white;
    color: white;

    &:hover {
      background-color: white;
      color: $color-teal
    }
  }

  &.small {
    padding: 4px 12px;
    font-size: 12px;
    line-height: normal;
  }

  .spinner {
    display: block;
    height: 20px;
    width: auto;
    margin-left: 6px;
  }
}

.inline-buttons {
  display: flex;
  justify-content: space-between;

  > button {
    width: calc(50% - 8px);
  }
}


// Fields

.field,
input[type="text"]:not(.default),
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="password"],
select,
textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid $color-grey02;
  border-radius: 0;
  color: $color-text;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0;
  outline: none;
  padding: 8px 0;
  position: relative;
  width: 100%;
  z-index: 1;
  transition: border-color $input-duration;

  &:focus {
    border-color: $color-teal;

    + label {
      color: $color-teal;
    }
  }

  &::-webkit-input-placeholder { // Chrome/Opera/Safari
    color: $color-placeholder;
  }

  &::-moz-placeholder { // Firefox 19+
    color: $color-placeholder;
    opacity: 1; // Make color same in Firefox
  }

  &:-ms-input-placeholder { // IE 10+
    color: $color-placeholder;
  }

  &:-moz-placeholder { // Firefox 18-
    color: $color-placeholder;
    opacity: 1; // Make color same in Firefox
  }
}

input[type="text"].default {
  background: white;
  border-radius: $radius-sm;
  border: none;
  font-size: 14px;
  padding: 6px 10px;
}

.select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding-right: 24px;
  }

  &::after {
    @include icons();
    content: "\e912";
    font-size: 28px;
    color: inherit;
    position: absolute;
    pointer-events: none;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}

.input-group {
  position: relative;
  margin: 18px 0;

  > label {
    position: absolute;
    left: 0;
    top: 8px;
    transition: $input-duration top, $input-duration border-color;
    pointer-events: none;
    z-index: 2;
  }

  input[type=checkbox] {
    appearance: none;
    background-color: white;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: $color-teal;
    }

    &:checked::before {
      transform: scale(1);
    }
  }

  input,
  select,
  .select {
    margin: 0 !important;
  }

  input:focus + label,
  input.entered + label {
    top: -15px;
    font-size: 12px;
  }

  .input-add {
    font-size: 16px;
    position: absolute;
    right: 0;
    bottom: 12px;
    z-index: 3;
  }

  + .error {
    margin-top: 6px;
  }
}
