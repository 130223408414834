/*******************************************
Control bar
*******************************************/

.controls {
  background-color: rgba($color-control-bar, .80);
  border-radius: $radius-md;
  border: 1px solid rgba($color-grey05, .50);
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 10px);
  max-width: 540px;
  padding: 7px 12px;
  z-index: 3;

  @include break-max(375px) {
    width: 100%;
    border-radius: 0;
    bottom: 0;
  }

  @include break-min($break-lrg + 1) {
    bottom: auto;
    top: 16px;
    opacity: 0;
    width: auto;
    transition: opacity $controls-duration, top $controls-duration;
    pointer-events: none;
    padding: 7px 24px;

    &.visible {
      top: 32px;
      opacity: 1;
      pointer-events: initial;
    }
  }

  .control-btn {
    background: transparent;
    border-radius: $radius-md;
    cursor: pointer;
    padding: 9px 12px;
    margin: 0 4px;
    transition: $hover-duration background-color;
    position: relative;

    @include break-min($break-lrg + 1) {
      padding: 9px 16px;

      &:hover {
        background-color: #4F5455;
  
        .control-btn-label {
          opacity: 1;
        }
      }
    }

    > i {
      color: white;
      display: block;
      font-size: 32px;
      line-height: 32px;

      &.icon-phone {
        color: $color-danger;
      }

      @include break-max(375px) {
        font-size: 26px;
        line-height: 26px;
      }
    }

    .control-btn-label {
      position: absolute;
      top: 66px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: max-content;
      padding: 0 5px;
      opacity: 0;
      background: $color-grey06;
      border-radius: 5px;
      transition: $hover-duration opacity;
      pointer-events: none;

      > label {
        font-size: 13px;
        color: $color-grey02;
      }
    }

    &.mute-warning {
      background-color: #4F5455;

      .control-btn-label {
        opacity: 1;
        width: 250px;
      }
    }

    &.selected {
      background: $color-danger;
    }

    &.sharing-screen {
      background: $color-warn;
    }

    @include break-min($break-lrg + 1) {
      &.mobile {
        display: none
      }
    }
  }
}
