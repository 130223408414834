/*******************************************
Alerts
*******************************************/

.alerts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 100;
  overflow: auto;

  @include break-min(1400px) {
    top: 32px;
    right: 12px;
    left: auto;
    max-width: 500px;
  }

  .alert {
    display: flex;
    flex-direction: column;
    color: white;
    min-height: 56px;
    padding: 12px 24px;
    margin-bottom: 2px;
    pointer-events: initial;
    width: 100%;
    max-width: 500px;
    position: relative;

    @include break-min(401px) {
      flex-direction: row;
      align-items: center;

      .alert-actions {
        margin-left: auto;
      }
    }

    @include break-min(1400px) {
      margin-bottom: 8px;
      max-width: none;
    }

    .alert-content {
      flex: 1;
      padding-right: 16px;
      font-size: 13px;
      line-height: 24px;
    }

    .alert-actions {
      display: flex;
      align-items: center;

      button {
        margin: 0;
      }

      @include break-max(400px) {
        margin-top: 14px;

        button {
          &.transparent {
            order: 2;
          }

          &.outline {
            order: 1;
          }
        }
      }
    }

    .close-alert {
      color: rgba(white, 0.70);
      position: absolute;
      top: 20px;
      right: 24px;

      &:hover {
        color: white;
      }
    }

    &.neutral {
      background-color: $color-grey05;
    }

    &.positive {
      background-color: $color-teal;
    }

    &.warn {
      background-color: $color-warn;
    }

    &.negative {
      background-color: $color-danger;
    }
  }
}
