// image-selector styles
.image-selector {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-top: 0.7rem;

  .image-content {
    width: 7em;
    height: 4.5em;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;
  }

  .image-option-selected {
    outline: solid #00A8B2;
    border: none;
  }

  .image-none {
    border-radius: 0.3rem;
    background-color: $color-grey01;
    border: solid $color-grey02;
    label {
      cursor: default;
    }
  }

  .image-label {
    position: absolute;
    color: $color-grey01;
    font-weight: 500;
  }
}