/*******************************************
Modal
*******************************************/

.modal {
  background-color: rgba($color-grey06, 0.76);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  transition: $modal-duration background-color;

  .modal-content {
    background-color: white;
    border-radius: $radius-md;
    width: 100%;
    max-width: 560px;
    overflow: hidden;
    position: relative;
    transform: scale(1);
    transform-origin: bottom;
    opacity: 1;
    transition: $modal-duration opacity, $modal-duration transform;

    .modal-tab {
      display: inline-flex;
      justify-content: space-around;

      .modal-tab-option-inactive {
        border-bottom: 0.2rem solid grey;
      }

      .modal-tab-option-active {
        border-bottom: 0.2rem solid $color-teal;

        label, i {
          color: $color-teal;
        }
      }

      .modal-tab-option-active,
      .modal-tab-option-inactive{
        width: 45%;
        text-align: center;
        justify-content: center;
      }

      i {
        font-size: 16px !important;
        margin-right: 4px;
      }
    }

    .modal-body {
      display: flex;
      padding: 40px 64px;
      flex-direction: column;

      @include break-max($break-sm) {
        padding: 24px 32px;
        flex: 1;

        h1 {
          font-size: 24px;
        }
      }

      > .leave-room-modal {
        h2 {
          font-size: 25px;
          margin-bottom: 8px;
        }
      }

      h5 {
        font-size: 14px;
      }

      &.center {
        text-align: center;
        align-items: center;
      }

      &.small-min-height {
        min-height: 500px;
      }
    }

    &.small {
      max-width: 440px;
    }

    &.center {
      text-align: center;
      align-items: center;
    }

    p.code {
      word-wrap: break-word;
    }

    .button-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: auto;
    }

    .close-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8px;
      right: 8px;
      width: 32px;
      height: 32px;

      > i {
        font-size: 16px;
      }
    }

    h1 {
      margin-bottom: 8px;

      + .input-group {
        margin-top: 24px;
      }

      &.center {
        text-align: center;
        align-items: center;
      }
    }

    h5 {
      margin-bottom: 8px;

      + .input-group {
        margin-top: 24px;
      }

      &.center {
        text-align: center;
        align-items: center;
      }
    }

    a {
      margin-bottom: 8px;

      &.center {
        text-align: center;
      }
    }

    .modal-footer {
      padding: 20px 64px 26px;
      background: #F5F5F5;

      @include break-max($break-sm) {
        padding: 20px 32px 26px;
      }

      h5 {
        font-weight: normal;
        margin: 0 0 10px;
      }

      .copy-link {
        display: flex;

        input.default {
          color: $color-placeholder;
          flex: 1;
          margin-right: 8px;
        }

        button {
          height: 32px;
          padding: 0 24px;
        }
      }
    }

    .modal-icon-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      font-weight: 600;

      > i {
        color: $color-grey05;
        display: block;
        font-size: 24px;
        line-height: 24px;
        padding-right: 4px;
      }
    }
  }

  &:not(.mobile-modal) {
    .modal-content {
      @include break-max($break-sm) {
        border-radius: 0;
        height: 100%;
        width: 100%;
        max-width: none !important;
        overflow: auto;
      }
    }
  }

  &.mobile-modal {
    @include break-max($break-sm) {
      .modal-content {
        margin: 10px;

        .modal-body {
          padding: 20px 10px;
        }
      }
    }
  }

  &.media-permissions-modal {
    img {
      margin-bottom: 8px;
      width: 100%;
      height: auto;
    }
  }

  &.media-permissions-guide-modal {
    iframe {
      width: 432px;
      height: 243px;
    }

    @include break-max($break-sm) {
      iframe {
        margin-bottom: 8px;
        width: 100%;
        height: auto;
      }
    }
  }

  &.help-modal {
    .support-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      margin-bottom: 24px;

      .vertical-bar {
        width: 1px;
        height: 28px;
        background-color: black;
      }

      button {
        background-color: transparent !important;
        color: $color-teal !important;
      }

      i {
        font-size: 24px;
        margin-right: 10px;
      }

      @include break-max($break-sm) {
        button {
          font-size: 12px;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  &.media-devices-missing-modal {
    .device-list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      flex: 0 0 auto;
      margin-bottom: 24px;

      .device {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 0 0 auto;
        color: $color-grey05;
        font-size: 16px;

        i {
          font-size: 24px;
          color: $color-teal !important;
        }

        .container-icon {
          display: flex;
          flex-direction: column;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        &.microphone {
          i::before {
            left: -2px;
          }
        }
      }
    }
  }
}
