/*******************************************
Drawer
*******************************************/

.drawer {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
  padding: 24px 32px;
  width: 100%;
  max-width: 440px;
  transition: $drawer-duration transform;

  h1 {
    margin-bottom: 3px;

    + p {
      margin-top: 0;
    }
  }

  .close-drawer {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    > i {
      font-size: 16px;
    }
  }

  &.right {
    right: 0;
    transform: translateX(100%);

    &.active {
      transform: translateX(0);
    }
  }

  &.left {
    left: 0;
    transform: translateX(-100%);

    &.active {
      transform: translateX(0);
    }
  }
}
