/*******************************************
Global Variables
*******************************************/

// Text
$light: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;


// Colors
$color-teal: #00A8B2;
$color-teal-dark: #166A6F;
$color-green: #54BF89;
$color-grey01: #E9EAEB;
$color-grey02: #D5D7D8;
$color-grey03: #ADB1B3;
$color-grey04: #83898C;
$color-grey05: #83898C;
$color-grey06: #303A40;
$color-grey07: #000000;

$color-brand: $color-teal;
$color-control-bar: $color-grey07;
$color-video-bg: $color-grey07;

$color-placeholder: $color-grey04;
$color-text-light: $color-grey04;
$color-text: $color-grey06;
$color-link: $color-teal;

$color-active: $color-green;

$color-positive: $color-green;
$color-warn: #E6A904;
$color-danger: #ED313F;

$color-button-default: $color-teal;
$color-button-dark: $color-grey07;

// Border radius
$radius-xs: 2px;
$radius-sm: 4px;
$radius-md: 8px;
$radius-lg: 16px;


// Transition Durations
$hover-duration: 0.15s;
$input-duration: 0.15s;
$drawer-duration: 0.4s;
$modal-duration: 0.25s;
$alert-duration: 0.15s;
$controls-duration: 0.25s;
