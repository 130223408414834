@font-face {
  font-family: 'evisit-icons';
  src:  url('../icons/evisit-icons.eot?xn1rrc');
  src:  url('../icons/evisit-icons.eot?xn1rrc#iefix') format('embedded-opentype'),
    url('../icons/evisit-icons.ttf?xn1rrc') format('truetype'),
    url('../icons/evisit-icons.woff?xn1rrc') format('woff'),
    url('../icons/evisit-icons.svg?xn1rrc#evisit-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icons() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'evisit-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  @include icons();
}

.icon-deny:before {
  content: "\e900";
}
.icon-phone-ringing:before {
  content: "\e901";
}
.icon-paper-plane:before {
  content: "\e902";
}
.icon-add:before {
  content: "\e903";
}
.icon-camera-switch:before {
  content: "\e904";
}
.icon-camera:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e906";
}
.icon-mic-off:before {
  content: "\e907";
}
.icon-mic:before {
  content: "\e908";
}
.icon-phone:before {
  content: "\e909";
}
.icon-remove:before {
  content: "\e90a";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-user:before {
  content: "\e90c";
}
.icon-video-off:before {
  content: "\e90d";
}
.icon-video:before {
  content: "\e90e";
}
.icon-volume-off:before {
  content: "\e90f";
}
.icon-volume:before {
  content: "\e910";
}
.icon-screenshare:before {
  content: "\e911";
}
.icon-arrow-down:before {
  content: "\e912";
}
.icon-user-md:before {
  content: "\e913";
}
.icon-user-md-dash:before {
  content: "\e914";
}
.icon-vertical-dots:before{
  content: "\e915";  
}
.icon-sparkles:before {
  content: "\e916";
}
.icon-cog:before{
  content: "\e994";  
}