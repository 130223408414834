/*******************************************
General Styles and Classes
*******************************************/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

html {
  color: $color-text;
  font-family: 'Open Sans', 'Helvetica', sans-serif;
  font-size: 14px;
  line-height: normal;
}

body {
  background: $color-grey06;
  color: $color-text;

  &.noscroll {
    overflow: hidden;
  }
}

label, .meta {
  color: $color-placeholder;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-text;
  font-weight: $light;
  margin: 0 0 24px;
}

h1 {
  font-size: 28px;
  line-height: 40px;
}

h2 {
  font-size: 20px;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4 {
  font-size: 16px;
  line-height: 24px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  line-height: 24px;
}

a {
  cursor: pointer;
  color: $color-text;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  font-size: 16px;
  margin: 0 0 24px;

  small {
    font-size: 14px;
  }
}

ul {
  margin: 0;
  padding: 0 15px;

  li {
    margin: 0 0 12px;
  }
}

hr {
  margin: 14px 0;
  border: none;
  border-bottom: 1px solid $color-grey02;
}

button {
  &.link {
    background-color: transparent !important;
    border-width: 0 !important;
    display: inline-block !important;
    color: $color-teal !important;
  }

  &.link:hover {
    text-decoration: underline;
  }
}

// Global Classes

.icon-btn {
  color: rgba($color-text, 0.70);
  cursor: pointer;

  > i {
    display: block;
  }

  &:hover {
    color: $color-text;
  }
}

.error,
.danger {
  color: $color-danger;
  font-size: 14px;
  font-weight: $semi-bold;
}

.loader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  z-index: 0;

  .spinner {
    display: block;
    height: inherit;
    width: inherit;
  }
}

.modal-spinner {
  position: inherit;
  padding: 10px;
  margin: auto;
  width: 48px;
  height: 48px;

  .spinner {
    height: inherit;
    width: inherit;
  }
}
